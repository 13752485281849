import React, {
  FunctionComponent,
  MouseEventHandler,
  useEffect,
  useMemo,
  useState,
} from "react";
// @ts-ignore
import YouTube from "react-youtube";
import LoadingIndicator from "../../../onboarding/LoadingIndicator";
import useUserRole from "../../../../../utils/hooks/useUserRole";
import { UserAssignmentRole } from "../../../../../store/auth/types";
import { useSelector } from "react-redux";
import { ApplicationState } from "../../../../../store";

type OwnProps = {
  onClick: MouseEventHandler<any>;
  messageParts?: Array<string>
  fromRecommendation?: boolean;
};

type Props = OwnProps;

const IntroPanel: FunctionComponent<Props> = (props) => {
  const [loading, setLoading] = useState<boolean>(false);
  const showDiagnosedStudentCheck = useSelector<
  ApplicationState,
  boolean
>((s) => s.dataFilter.showDiagnosedStudentFilter);

  useEffect(() => {
    setLoading(true);
  }, []);

  const { role, isCoach } = useUserRole();

  const info = useMemo(() => {
    switch (role) {
      case UserAssignmentRole.COACH:
      case UserAssignmentRole.TEACHER:
        return `Get started by uploading data. For help uploading data, 
        access the video tutorial in the Explore and Learn section.`;
      case UserAssignmentRole.SUPERVISOR:
        return "Wait until teachers and coaches set up groups.";
      default:
        return null;
    }
  }, [role]);

  return (
    <div className="dataContainer text-center" data-cy="groups-container-intro">
      {props.fromRecommendation && showDiagnosedStudentCheck
      ?
        <div>
          <h2 className="font-weight-bold">
            Welcome! You do not have any students with diagnostics assigned in this data period.
          </h2>
          <h4 className="font-weight-bold mb-3">Get started by adding Diagnostic Skill Information in the data period.</h4>
        </div> 
      :
        <div>
          <h2 className="font-weight-bold">
            Welcome! You don’t have any recommended groups {props.messageParts?.length ? 'as per above filter': ''}. 
          </h2>
          <h4 className="font-weight-bold mb-3">{info}</h4>
          {/* {loading && <LoadingIndicator />}
          <div style={loading ? { display: "none", height: 0 } : undefined}>
            <YouTube
              onReady={() => setLoading(false)}
              videoId="0VX1gv8itB0"
              opts={{ width: "50%" }}
            />
          </div> */}
        </div>
      }

      {isCoach && (
        <button
          className="blueBtnLg mt-3"
          onClick={props.onClick}
          data-cy="start"
        >
          Get Started
        </button>
      )}
    </div>
  );
};

export default IntroPanel;
